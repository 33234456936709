<template>
  <div v-if="shouldDisplay" :id="uniqueId" class="is-ad" />
</template>

<script>
import postscribe from 'postscribe';

export default {
  props: {
    adSlot: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    uniqueId: `${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
  }),
  computed: {
    ad() {
      if (this.adSlot === 'LeaderboardAd') {
        return this.$store.state.advertisements.leaderboard_ads;
      } if (this.adSlot === 'RecordAd') {
        return this.$store.state.advertisements.record_ads;
      } if (this.adSlot === 'ResultsAd') {
        return this.$store.state.advertisements.results_ads;
      } if (this.adSlot === 'SearchBannerAd') {
        return this.$store.state.advertisements.search_banner_ads;
      } if (this.adSlot === 'LandingAd') {
        return this.$store.state.advertisements.landing_ads;
      }

      return null;
    },
    shouldDisplay() {
      if (this.adSlot === 'ResultsAd') {
        return this.ad // there are ads
          && this.index > 0 // the index is not 0
          && this.index % 4 === 0; // is congruent with 4
      }

      return this.ad;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.shouldDisplay) {
        postscribe(`#${this.uniqueId}`, this.ad);
      }
    }, 1000);
  },
};
</script>
